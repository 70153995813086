import { useSelector } from "react-redux";
import { SessionStoreState } from "Store";
import { AuthenticationResponse } from "Types/Authentication/UserModel";
import { sessionService } from "@nickcaseg3r/redux-react-session";


const useIsAuthenticated = (): boolean => {
    const session = useSelector((state: SessionStoreState) => state.session.authenticated);
    return session;
};

const useSession = (): AuthenticationResponse => {
    return sessionService.loadSession();
};

export { useIsAuthenticated, useSession };
