import { Loading } from "@g3r-developers/g3-common";
import { sessionService } from "@nickcaseg3r/redux-react-session";
import * as Sentry from "@sentry/react";
import { useSession } from "Hooks/UseSession";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "Store";
import "./index.scss";
import { SentryDsn } from "./Types/envVariables";

const App = React.lazy(() => import("./App"));

const sentryDsn = SentryDsn;

if (sentryDsn) {
    Sentry.init({
        dsn: sentryDsn,
        transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
        ignoreErrors: ["Non-Error exception captured", "Non-Error promise rejection captured"],
    });
}

const validateSession = session => {
    if (!session) {
        return false;
    }

    const date = new Date(session.expires);

    if (date < new Date()) {
        return false;
    }
    const user = useSession();

    if (user.username) {
        Sentry.setUser({
            username: user.username,
        });
    }
    return true;
};

interface SessionServiceOptions {
    redirectPath: string;
    refreshOnCheckAuth: boolean;
    validateSession(session: any): boolean;
    driver: string;
    expires: number;
}

const options = {
    redirectPath: "/",
    refreshOnCheckAuth: true,
    validateSession,
    driver: "INDEXEDDB",
    expires: 9999999999,
} as SessionServiceOptions;

const root = createRoot(document.getElementById("root") as HTMLElement);

sessionService.initSessionService(store, options).then(() => {
    root.render(
        <Suspense fallback={<Loading />}>
            <Provider store={store}>
                <React.StrictMode>
                    <App />
                </React.StrictMode>
            </Provider>
        </Suspense>,
    );
});

window.addEventListener("vite:preloadError", event => {
    try {
        event.preventDefault();
        window.location.reload();
    } catch {
        // ignored
    }
});
