import { sessionReducer } from "@nickcaseg3r/redux-react-session";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

export interface SessionStoreState {
    session: {
        authenticated: boolean;
        checked: boolean;
        invalid: boolean;
    };
}

const reducer = combineReducers({
    session: sessionReducer,
});

export const store = configureStore({
    reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
